/* glance.module.css */
.fullW {
    width: 90%;
    margin: 10px auto;
    padding-top: 20px;
  }
  
  .content {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .imageContent {
    width: 100%;
  
    @media (min-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .image {
    width: 100%;
    height:100%;
  
    @media (min-width: 768px) {
      border-radius: 2rem;
    padding-top: 70px;

    }
  }
  