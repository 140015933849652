.fullW{
    width: 80%;
    height: auto;
    margin: 10px 10%;
    padding: 100px 0px 10px 0px;
}

.gridR{
    width: 100%;
    height: auto;
    margin: 10px auto;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3,1fr);
}

@media only screen and (max-width: 768px) {
    /* CSS rules for mobile devices */
    .fullW{
        width: 100%;
        height: auto;
        margin: 10px auto;
    padding: 90px 0px;
    }
    .gridR{
        width: 90%;
        height: auto;
        margin: 10px auto;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
    }
  }