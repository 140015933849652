.fullW{
    width: 80%;
    height: auto;
    margin: 10px auto;
    padding: 10px 0px;
}

@media only screen and (max-width: 768px) {
    /* CSS rules for mobile devices */
    .fullW{
        width: 90%;
        height: auto;
        margin: 10px auto;
    padding: 10px 0px;

    }
  }