.h1{
    width: 80%;
    height: auto;
    text-align: center;
    font-weight:400;
    color: black;
margin: 10px auto;
}
.hero_p1{
    width: 60%;
    height: auto;
    text-align: center;
    font-weight:400;
    color: rgb(54, 53, 53);
font-size:13px;
margin: 10px auto;
}


@media only screen and (max-width: 767px) {
    /* CSS rules for mobile devices */
    .h1{
        width: 95%;
        padding: 0px;
        font-family: 'Times New Roman', Times, serif;
        font-size: 25px;
        font-weight: 600;
        margin-top: -20px;
    }
    .hero_p1{
        width: 80%;
        height: auto;
        text-align: center;
        font-weight:400;
        color: rgb(54, 53, 53);
    font-size:13px;
    margin: 10px auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    /* CSS rules for tablet devices */
    .h1{
        font-family: 'Times New Roman', Times, serif;
        font-weight:400;
        color: black;
        font-size: 50px;
margin: 10px auto;
    }
    .hero_p1{
        width: 60%;
        height: auto;
        text-align: center;
        font-weight:400;
        color: rgb(54, 53, 53);
    font-size:15px;
    margin: 10px auto;
    }
  }

  @media only screen and (min-width: 1025px) {
    /* CSS rules for desktop devices */
    .h1{
        width: 80%;
        font-family: 'Times New Roman', Times, serif;
        font-weight:400;
        color: rgb(34, 33, 33);
        font-size: 88px;
margin: 10px auto;
    }
    .hero_p1{
        width: 40%;
        height: auto;
        text-align: center;
        font-weight:400;
        color: rgb(54, 53, 53);
    font-size:15px;
    margin: 10px auto;
    }
  }
      