.carousel {
    position: relative;
    overflow: hidden;
    
    
}
  
  .carousel__slides {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .carousel__slide {
    flex: 0 0 100%;
    width: 100%;
  }
  
  .carousel__slide img {
    width: 100%;
    height: auto;
  }
  
  .carousel__buttons {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .carousel__button {
    background: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
  
  .carousel__button:hover {
    opacity: 1;
  }
  
  .carousel__button--prev {
    position: absolute;
    left: 20px;
  }
  
  .carousel__button--next {
    position: absolute;
    right: 20px;
  }
  
  .carousel__dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  
  .carousel__dot {
    background: white;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 6px;
    border: none;
    padding: 0;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .carousel__dot.active {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .carousel__button {
      font-size: 1.5rem;
    }
  }
  