.fullW{
    width: 80%;
    height: auto;
    margin: 10px auto;

}

/* Add these styles to your CSS */
.hover-shadow {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Initial shadow style */
  }
  
  .hover-shadow:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Shadow style on hover */
  }
  

@media only screen and (max-width: 768px) {
    /* CSS rules for mobile devices */
    .fullW{
        width: 90%;
        height: auto;
        margin: 10px auto;
    }
  }